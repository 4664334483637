
































































import { Component, Vue } from 'vue-property-decorator'
import { apiGetUserInfo } from '@/api/profile'
import { question, getQuestion } from '@/api/users'
import Bind from '@/components/Dialog/bind.vue'
import { Form } from 'element-ui'
@Component({
  name: 'Form',
  components: {
    Bind
  }
})

export default class extends Vue {
  private radionOptions = [
    {
      title: '网易云',
      value: '网易云'
    },
    {
      title: 'QQ音乐',
      value: 'QQ音乐'
    },
    {
      title: '酷狗',
      value: '酷狗'
    },
    {
      title: '抖音',
      value: '抖音'
    },
    {
      title: '快手',
      value: '快手'
    },{
      title: '其他',
      value: '其他'
    }
  ]
  private rules = {
    channel: [
      { required: true, message: '请选择平台', trigger: 'blur' },
    ],
    nickname: [
      { required: true, message: '请输入你在该平台的昵称', trigger: 'blur' },
      { min:1, max: 40, message: '昵称限制1-40字符', trigger: 'blur' },
    ],
    fans: [
      { required: true, message: '请输入你在该平台的粉丝量', trigger: 'blur' }
    ]
  }
  private labelPosition = 'top'
  private form = {
    nickname: '',
    channel: '',
    fans: '',
    wxcode: ''
  }
  private isDone = false
  private isBindPhone = false
  private dialogVisible = false
  private otherWayToClose = false;
  private submitHandle() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if(valid) {
        question({
          tempid: 1,
          cols: [this.form.channel, this.form.nickname, this.form.fans, this.form.wxcode]
        }).then((res: any)=> {
          console.log(res)
          this.isDone = true
        })
      }else{
        this.$message({
          message: '请按照要求填写信息',
          type: 'warning'
        })
      }
    })
  }
  created() {
    this.getInfo()
  }
  private checkBindPhone():void {
    apiGetUserInfo().then((res: any)=> {
      if(res.data) {
        if(!res.data.phone) {
          this.isBindPhone = true
          this.dialogVisible = true
        }
      }
    })
  }
  private getInfo():void {
    getQuestion().then((res) => {
      if(res.data.template_id !== 0) {
        this.isDone = true
        this.form.channel = res.data.col_1
        this.form.nickname = res.data.col_2
        this.form.fans = res.data.col_3
        this.form.wxcode = res.data.col_4
      }else{
        this.checkBindPhone()
      }
    })
  }
  private closeDiaHandle():void {
    this.dialogVisible = false
  }
}
